<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>数据总览</h3>
            </div>
            <User></User>
        </div>
        <div class="cont-box">
            <!-- 发票信息 -->
            <div class="invoice-head">
                <h4>发票详情</h4>
                <ul>
                    <li>{{data.comname}}</li>
                    <li>开户银行 {{data.bankName}}</li>
                    <li>电话号码 {{data.comname}}</li>
                    <li>单位地址 {{data.comadress}}</li>
                    <li>税号 {{data.dutyCode}}</li>
                    <li>银行账号 {{data.banCode}}</li>
                    <li>{{data.companypro}}</li>
                </ul>
            </div>
            <div class="invoice-upload">
                <div class="upload-header">
                    <div class="upload-header-title">
                        <h4>发票详情</h4>
                        <!-- <span>（99条）</span> -->
                    </div>
                    <el-date-picker v-model="value" type="month" format="yyyy 年 M 月" :clearable="false"
                        prefix-icon="none" :editable="false" align="center" @change="dateInputChange">
                    </el-date-picker>
                </div>
                <div>
                    <!-- 表格 -->
                    <div class="upload-header-tabble">
                        <el-table :show-header="false" :data="tableData" style="width: 100%">
                            <el-table-column prop="createtime" label="日期" width="180"> </el-table-column>
                            <el-table-column prop="billFile" label="文件" width="180">
                                <template slot-scope="scope" class="upload-span">

                                    <span style="color: #2e4fff;" @click="OpenSee(scope.row.billFile)">《发票》</span>
                                    <span style="color: #2e4fff;" @click="OpenSee(scope.row.accountFile)">《账单》</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="billCode" label="单号">
                                <template slot-scope="scope">
                                    <span>结算单号：{{scope.row.billCode}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="billMoney" label="金额">
                                <template slot-scope="scope">
                                    <span>结算单含税收入(元)：{{scope.row.billMoney}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="status" label="状态">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.status == 0">未结算</span>
                                    <span v-if="scope.row.status == 1">已结算</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next" :total="tableDataCount"
                            @current-change="currentChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import User from "./../../components/user.vue"
export default {
    components:{
        User
    },
    data() {
        return {
            value: new Date(),
            data:[],//配置信息
            tableData: [],// 表格数据
            tableDataCount: 0,
            code: 1,
        }
    },
    created(){
        this.PostDatainvoice(this.value);//发票列表
        this.Postconfig();//配置信息
    },
    methods: {
        // 发票列表
        PostDatainvoice(date){
            let userID = localStorage.getItem('userID');
            let time = this.$formDate(Date.parse(date),'M','-');

            this.$api.Datainvoice({
                userId:userID,
                time:time,
                pagecode:this.code,
                pagesize:10,
            }).then(res=>{
                res.data.forEach(item=>{
                    item.createtime = this.$formDate(item.createtime,'m','-',1000)
                })
                this.tableData = res.data
                this.tableDataCount = res.count;
                // <!-- billFile，accountFile -->
            })
        },
         // 配置信息
         Postconfig(){
            this.$api.config().then(res=>{
                this.data = res
            })
        },
        dateInputChange(e){
            this.PostDatainvoice(e)
        },
        currentChange(e) {
            this.code = e

            this.PostDatainvoice(this.value);//发票列表
        },
        // 查看
        OpenSee(url){
            this.$see(url)
        }
    }
}
</script>
<style scoped>
.content {

    min-height: 100vh;
    background-color: #eee;
}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cont-box {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
}

.cont-box h4 {
    font-size: 1em;
    color: #000000;
}

.invoice-head ul {
    margin-top: 1em;
    display:flex;
    flex-wrap: wrap;
}
.invoice-head ul li{
    list-style-type: disc;
    line-height: 2.5em;
    margin-right: 10px;
    width: 23%;
    min-width: 10em;
    color: #a6a9b7;
}

.invoice-upload {
    margin-top: 1em;
}

.upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-header-title {
    display: flex;
}

.upload-header-tabble {
    margin-top: 1em;
}

.upload-header-title span {
    margin-left: 0.5em;
    color: #4c4c4c;
}

.pagination {
    margin-top: 1em;
    text-align: center;
}

</style>